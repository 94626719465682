export const mixin = {
  data() {
    return {
      // 球镜区间
      sectionMin: -24,
      sectionmax: 24,
      // 柱镜区间
      sectionMinZJ: -8,
      sectionmaxZJ: 8,
      // 间隔
      difference: 0.25,
      sectionList: [],
      // 间隔列表
      spanList: ['0.25', '0.5', '0.75', '1', '1.5', '2'],
      //直径区间
      sectionMinDiameter: 50,
      sectionMaxDiameter: 80,
      //直径间隔
      diameterDifference: 10,
      //直径间隔列表
      diameterSpanList: ['10', '20', '30']
      ,//域展开收起开关
      searchShow: false,
      //搜索区域展开收起图标
      searchOpenIcon: 'el-icon-arrow-down',
      //加载
      tableLoading: false,
      //表格高度
      tableHeight: 0,
      //表格数据
      tableData: [],
      //当前页面
      currentPage: 1,
      //总页数
      total: 0,
      //搜索表单
      SKURules: {
        goodsGiCustomerId: [
          { required: true, message: '此项不可为空', trigger: 'blur' }
        ],
        goodsGiBrandId: [
          { required: true, message: '此项不可为空', trigger: 'blur' }
        ],
        goodsGiProductId: [
          { required: true, message: '此项不可为空', trigger: 'blur' }
        ],
        sku: [
          { required: true, message: '此项不可为空', trigger: 'blur' }
        ],
        splitCode: [
          { required: true, message: '此项不可为空', trigger: 'blur' }
        ],
      },
      //保存表单验证
      saveRules: {
        splitCode: [
          { required: true, message: '请输入SKU分割符号', trigger: 'blur' }
        ],
        goodsGiCustomerId: [
          { required: true, message: '请选择所属制造商', trigger: 'change' }
        ],
        goodsGiProductId: [
          { required: true, message: '请选择所属品种', trigger: 'change' }
        ],
        goodsGiBrandId: [
          { required: true, message: '请选择所属品牌', trigger: 'change' }
        ],
        goodsGiSku: [
          { required: true, message: '请输入SKU前缀', trigger: 'blur' }
        ],
        goodsCategory: [
          { required: true, message: '请选择商品类型', trigger: 'change' }
        ],
        goodsIciSph: [
          { required: false, validator: this.$verification.goodsIciSphTest, trigger: 'blur' }
        ],
        goodsIciCyl: [
          { required: false, validator: this.$verification.goodsIciCylTest, trigger: 'blur' }
        ],
        goodsIciAxial: [
          { pattern: this.$regular.regAxial, message: '请输入正确格式', trigger: 'blur' }
        ],
        goodsGiStatus: [
          { required: true, message: '请选择启用状态', trigger: 'blur' }
        ],
        sectionList: [
          { required: true, message: '请选择区间', trigger: 'blur' }
        ],
        goodsGiName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        skuList: [
          { required: true, message: '请添加商品SKU', trigger: 'blur' }
        ],
        goodsGiModel: [
          { required: true, message: '请添加商品型号', trigger: 'blur' }
        ],
        goodsGiColour: [
          { required: true, message: '请添加商品色号', trigger: 'blur' }
        ],
        goodsGiDegree: [
          { required: true, message: '请添加商品度数', trigger: 'blur' }
        ],
      },
      searchRules: {
        goodsIciSph: [
          { pattern: this.$regular.regSph, message: '请输入正确格式', trigger: 'blur' }
        ],
        goodsIciCyl: [
          { pattern: this.$regular.regSph, message: '请输入正确格式', trigger: 'blur' }
        ],
        goodsIciAxial: [
          { pattern: this.$regular.regAxial, message: '请输入正确格式', trigger: 'blur' }
        ],
      },
      searchForm: {
        page: 1,
        pageSize: this.$store.state.initPageSize,
        goodsCategory: '',
        goodsGiAxial: '',
        goodsGiBrandId: '',
        goodsGiCustomerId: '',
        goodsGiCyl: '',
        goodsGiDia: '',
        goodsGiId: '',
        goodsGiName: '',
        goodsGiProductId: '',
        goodsGiSku: '',
        goodsGiSph: '',
        goodsGiStatus: '',
        goodsGiVersion: '',
        order: '',
        orderByColumn: '',
        userid: ''
      },
      //查看模态框开关
      dialogVisibleSee: false,
      addSkuDialogVisible: false,
      seeForm: {
        customerName: '',
        goodsPiName: '',
        goodsPiSku: '',
        goodsIciSph: '',
        goodsIciCyl: '',
        goodsIciAxial: '',
        goodsIciStatus: '',
        brandName: ''
      },
    }
  },

  methods: {
    /****表格高度计算 start********************************************************************************/
    //计算表格区域高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
        window.onresize = () => {
          return (() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
          })()
        }
      })
    },
    /****表格高度计算 end********************************************************************************/

    //搜索展开收起事件
    searchOpen() {
      this.searchShow = !this.searchShow
      this.searchOpenIcon = this.searchShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
    },

    //模态框关闭前回调
    handleClose() {
      if (this.$store.state.closeModel) {
        this.$confirm('关闭后数据不会保存,确认关闭？')
          .then(_ => {
            this.dialogVisibleSave = false
            this.clearForm('saveForm')
          }).catch(_ => {
          })
      } else {
        this.dialogVisibleSave = false
        this.clearForm('saveForm')
      }

    },

    //停启用按钮
    stopBtn(formName) {
      // console.log(formName)
      //  yyy
      this.$server.saveProductInfo(formName).then(res => {
        if (res.ok) {
          this.$message.success(res.msg)
          this.searchSubmit(false,'searchForm');
        } else {
          this.$message.error(res.msg)
          this.searchSubmit(false, 'searchForm');
        }
        this.dialogVisibleSave = false
        this.$common.clearFormValue(this.saveForm)
      }).catch(e => {
        console.log(e)
      })
    },
    /****删除区域 start********************************************************************************/
    delOpen(row) {
      this.$confirm('确认删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.deleteProductItem({ id: row.goodsGiId }).then((res) => {
          if (res.ok) {
            console.log(this.searchForm, '保存提交', JSON.stringify(res.data))
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.searchSubmit(false, 'searchForm')
          } else {
            this.$message.error(res.msg)
          }
        }).catch(e => {
          console.log(e)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    /****删除区域 end********************************************************************************/

    //排序
    sortChange(row) {
      console.log('排序排序排序', JSON.stringify(row))
      if (row.prop == 'customerName') {
        this.searchForm.orderByColumn = 'goodsIciId'
      } else if (row.prop == 'productName') {
        this.searchForm.orderByColumn = 'goodsIciId'
      } else {
        this.searchForm.orderByColumn = row.prop
      }
      if (row.order == 'ascending') {
        this.searchForm.order = 'asc'
      } else if (row.order == 'descending') {
        this.searchForm.order = 'desc'

      } else {
        this.searchForm.order = ''
      }
      // this.searchForm.page = 1;
      this.searchSubmit(true, 'searchForm')
    },

    toS(value) {
      let newValue = JSON.parse(JSON.stringify(value))
      newValue.map((item) => {
        let val = item
        for (let key in val) {
          val[key] = val[key] + ''
        }
        return val
      })
      return newValue
    },

    seeOtherSKU(item) {
      this.currentEditRow = item
      this.seeSKUDialog = true
    },

    getOtherSKU() {
      this.otherSKUList = []
      this.$server.getOtherSKU({ goodsGiId: this.currentEditRow.goodsGiId }).then(res => {
        this.otherSKUList = res.data
      })
    },

    // 批量添加sku
    bathAddSku() {
      this.addSKUDialog = true
    },

    closeOtherDialog() {
      this.skuList = []
      this.seeSKUDialog = false
      this.otherSKUList = []
    },

    setSku(val) {
      this.skuList = val
    },

    // 选择品时，sku回显
    selectGoods(val) {
      const flagSKU = this.ProductList.filter(item => item.goodsPiId == val)[0]?.goodsPiSku || ''
      this.$set(this.saveForm, 'goodsGiSku', flagSKU)
    },

    addSKUFast() {
      this.$refs.addSkuForm.validate().then(_ => {
        const productId = this.SKUform.goodsGiProductId
        this.$server.addSKUBatch({ productId, ...this.SKUform }).then(res => {
          if (res.ok) {
            this.addSKUDialog = false
            this.$message.success('批量添加成功')
          } else {
            this.$message.error(res.msg)
            this.addSKUDialog = false
          }
        })
      })
    },

    closeAddSKUDialog() {
      this.SKUform = {}
    },

    seeAddSkuDialog(item) {
      console.log(item, 'sku item')
      this.currentEditRow = item
      this.addSkuDialogVisible = true
      this.$nextTick(() => {
        this.$refs.skuSelect.reset()
      })
    },
    closeAddSkuDialog() {
      this.addSkuDialogVisible = false
    },
    /****查看区域 end********************************************************************************/
    handleSizeChange(val) {
      this.currentPage = 1
      this.currentPageSize = val
      this.searchForm.page = 1
      this.searchForm.pageSize = val
      this.searchSubmit(false, 'searchForm')
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.searchForm.page = val
      this.searchSubmit(false, 'searchForm')
    },
  },

  watch: {
    //监听头部展开收起变化
    '$store.state.isHeader'() {
      this.setTableHeight()
    },
    //监听快速导航展开收起变化
    '$store.state.fastNav'() {
      this.setTableHeight()
    },
    //监听搜索区域变化改变列表
    searchShow() {
      this.setTableHeight()
    },
  },

  mounted() {
    //初始化计算表格区域高度
    this.setTableHeight()
    //  初始化制造商列表
    this.manufacturer()
    //  初始化表单
    this.searchSubmit(false, 'searchForm')
    this.searchForm.productName = ''
    this.$server.getCategoryList().then(res => {
      this.productTypeList = res.data
    })

  },
}
