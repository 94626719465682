<template>
  <div>
    <div class="f mt mb5">
      <el-input v-model="target" placeholder="快速搜索SKU" @input="search"> </el-input>
      <div style="width:10px"></div>
      <el-button @click="target = null">清空</el-button>
    </div>
    <div class="bk">
      <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border :labelStyle='{ width: "200px" }'>
        <el-descriptions-item v-for="(item, index) in list" :key="item.goodsGsId">
          <template slot="label">
            {{ "SKU" + item.id }}
          </template>
          {{ item.goodsGsGoodsSku }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="flex_b">
      <el-pagination
        v-if="!isSearch"
        layout="prev, pager, next"
        :page-size="20"
        :current-page="current"
        :total="otherSKUList.length"
        @current-change="changeP"
      >
      </el-pagination>
      <div v-else class="tip">检索出 {{ long }} 条包含{{ `【${target}】` }}的非标准SKU</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkuListPage",

  props: {
    otherSKUList: Array,
  },

  computed: {
    list() {
      if (this.target) {
        const data = this.SKUData.filter(item => item.goodsGsGoodsSku.includes(this.target))
        this.long = data.length
        return data
      } else {
        this.isSearch = false
        return this.currentPage
      }
    },

    currentPage() {
      const list = this.SKUData
      const now = this.current
      return list.slice((now - 1) * 20, now * 20)
    },
  },

  data() {
    return {
      target: "",
      current: 1,
      SKUData: [],
      isSearch: false,
      long: 0,
    }
  },

  mounted() {
    this.SKUData = this.otherSKUList.reverse().map((item, index) => {
      return { ...item, id: index + 1 }
    })
  },

  methods: {
    search() {
      this.isSearch = true
      this.current = 1
    },

    changeP(val) {
      this.current = val
    },
  },
}
</script>

<style lang="scss" scoped>
.bk {
  padding: 5px;
  overflow: auto;
  // height: 100;
  height: calc(100vh - 530px);
  border: 2px solid #f5f7fa;
}

.mt {
  margin-top: -10px;
}

.flex_b {
  display: flex;
  justify-content: flex-end;
}

.tip {
  font-size: 20px;
  color: red;
  padding-right: 10px;
}

.f {
  display: flex;
  padding-right: 10px;
}
</style>
