<template>
  <div class="assembly-card-page">
    <el-card shadow="never" :body-style="{padding: '10px'}">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button type="text" icon="iconfont icon-reply" @click="back()">
          返回
        </el-button>
      </div>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "assembly-card-page",
  props:{
    title:{
      type: String,
      default:()=>{
        return ''
      }
    },
  },
  data(){
    return{

    }
  },
  methods:{
    //返回方法
    back() {
      this.$emit('back', false)
    },
  }
}
</script>

<style lang="scss">
.assembly-card-page{
  //卡片头部样式
  .clearfix {
    span {
      font-weight: bold;
    }

    .el-button {
      float: right;
      padding: 3px 0
    }
  }
}
</style>