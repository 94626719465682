<template>
   <div>
      <el-button size="mini" @click="add" v-if="haveButton">新增区间</el-button>
      <div v-if="inputData.length" class="flex_box_main">
         <div class="flex_box mt10" v-for="(item, key) in inputData" :key="key">
            <!-- 球镜 -->
            <div class="box_up fz12">
               球镜：
               <el-select @change="emitValue($event,key,'goodsPsSphStart')" style="width:90px" size="mini" placeholder="最小值" v-model="inputData[key].goodsPsSphStart">
                  <el-option :label="item" :value="item" v-for="item in section(sectionMin, sectionmax)" :key="item"></el-option>
               </el-select>
               至
               <el-select @change="emitValue($event,key,'goodsPsSphEnd')" style="width:90px" size="mini" placeholder="最大值" v-model="inputData[key].goodsPsSphEnd" :disabled="isgoodsPsSphEnd">
                  <el-option :label="item" :value="item" v-for="item in section(inputData[key].goodsPsSphStart + step, sectionmax)" :key="item"></el-option>
               </el-select>
               <span class="mr10"></span>
               间隔：
               <el-select @change="emitValue($event,key,'goodsPsSphSpan')" style="width:90px" size="mini" placeholder="间隔" :disabled="!inputData[key].goodsPsSphEnd" v-model="inputData[key].goodsPsSphSpan">
                  <el-option :label="item" :value="item" v-for="item in getSpan(inputData[key].goodsPsSphStart, inputData[key].goodsPsSphEnd)" :key="item"></el-option>
               </el-select>
               <!-- <el-button size="mini" class="del" type="danger ml20" @click="del(key)">删除</el-button> -->
            </div>
            <!-- 柱镜 -->
            <div class="box_up fz12 b">
               柱镜：
               <el-select @change="emitValue($event,key,'goodsPsCylStart')" style="width:90px" size="mini" v-model="inputData[key].goodsPsCylStart" placeholder="最小值">
                  <el-option :label="item" :value="item" v-for="item in section(sectionMinZJ, sectionmaxZJ)" :key="item"></el-option>
               </el-select>
               至
               <el-select @change="emitValue($event,key,'goodsPsCylEnd')" placeholder="最大值" style="width:90px" size="mini" v-model="inputData[key].goodsPsCylEnd" :disabled="isgoodsPsCylEnd">
                  <el-option :label="item" :value="item" v-for="item in section(inputData[key].goodsPsCylStart + step, sectionmaxZJ)" :key="item"></el-option>
               </el-select>
               <span class="mr10"></span>
               间隔：
               <el-select @change="emitValue($event,key,'goodsPsCylSpan')" placeholder="间隔" style="width:90px" size="mini" :disabled="!inputData[key].goodsPsCylEnd" v-model="inputData[key].goodsPsCylSpan">
                  <el-option :label="item" :value="item" v-for="item in getSpan(inputData[key].goodsPsCylStart, inputData[key].goodsPsCylEnd)" :key="item"></el-option>
               </el-select>
            </div>
            <div class='box_up b fl_right'>
               <el-button icon="el-icon-close" type="text" @click="del(key)">删除此条区间</el-button>
            </div>
            <!-- 直径 -->
            <!-- <div class="box_up fz12">
          直径：
          <el-select @change="emitValue" style="width:90px" size="mini" placeholder="最小值" v-model="inputData[key].goodsPsDiameterStart">
            <el-option :label="item" :value="item" v-for="(item, index) in numberList"></el-option>
          </el-select>
          至
          <el-select
            @change="emitValue"
            style="width:90px"
            size="mini"
            placeholder="最大值"
            v-model="inputData[key].goodsPsDiameterEnd"
            :disabled="!inputData[key].goodsPsDiameterStart"
          >
            <el-option :label="item" :value="item" v-for="(item, index) in ZJSection(inputData[key].goodsPsDiameterStart)"></el-option>
          </el-select>
          <span class="mr10"></span>
          间隔：
          <el-select
            @change="emitValue"
            style="width:90px"
            size="mini"
            placeholder="间隔"
            v-model="inputData[key].goodsPsDiameterSpan"
            :disabled="!inputData[key].goodsPsDiameterEnd"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in ZJSpan(inputData[key].goodsPsDiameterStart, inputData[key].goodsPsDiameterEnd)"
            ></el-option>
          </el-select>
        </div> -->
         </div>
      </div>
      <div class="empty mt5" v-else>
         <i class='el-icon-warning mr5'></i>
         暂无区间信息
      </div>
   </div>
</template>

<script>
export default {
   props: {
      data: {
         type: Array,
      },
      haveButton: {
         type: Boolean,
         default: true,
      },
   },

   data() {
      return {
         step: 0.25,
         inputData: [
            {
               goodsPsAddEnd: "",
               goodsPsAddSpan: "",
               goodsPsAddStart: "",
               goodsPsCreateTime: "",
               goodsPsCylEnd: "",
               goodsPsCylSpan: "",
               goodsPsCylStart: "",
               // goodsPsDiameterEnd: "",
               // goodsPsDiameterSpan: "",
               // goodsPsDiameterStart: "",
               goodsPsId: "",
               goodsPsProductId: "",
               goodsPsRemarks: "",
               goodsPsSphEnd: "",
               goodsPsSphSpan: "",
               goodsPsSphStart: "",
               goodsPsUpdateTime: "",
            },
         ],
         // 球镜区间
         sectionMin: -24,
         sectionmax: 24,
         // 柱镜区间
         sectionMinZJ: -8,
         sectionmaxZJ: 8,
         // 间隔
         difference: 0.25,
         sectionList: [],
         // 间隔列表
         spanList: ["0.25", "0.5", "0.75", "1", "1.5", "2"],
         // 直径区间
         sectionMinDiameter: 50,
         sectionMaxDiameter: 80,
         // 直径间隔
         diameterDifference: 10,
         // 直径间隔列表
         diameterSpanList: ["10", "20", "30"],
         numberList: ["50", "60", "70", "80"],
         isgoodsPsSphEnd: true,
         isgoodsPsCylEnd: true,
      };
   },

   methods: {
      reset() {
         this.inputData = [];
      },

      add() {
         const arr = this.inputData;
         const message = "请输入完整的区间信息再继续创建";
         if (arr.length && !this.isEmpty(arr[arr.length - 1])) {
            this.$message({
               center: true,
               type: "error",
               message,
            });
            return;
         }
         const data = {
            goodsPsAddEnd: "",
            goodsPsAddSpan: "",
            goodsPsAddStart: "",
            goodsPsCreateTime: "",
            goodsPsCylEnd: "",
            goodsPsCylSpan: "",
            goodsPsCylStart: "",
            // goodsPsDiameterEnd: "",
            // goodsPsDiameterSpan: "",
            // goodsPsDiameterStart: "",
            goodsPsId: "",
            goodsPsProductId: "",
            goodsPsRemarks: "",
            goodsPsSphEnd: "",
            goodsPsSphSpan: "",
            goodsPsSphStart: "",
            goodsPsUpdateTime: "",
         };
         this.inputData.push(data);
         this.$emit(
            "getSection",
            this.inputData,
            !!this.isEmpty(arr[arr.length - 1]) && !!this.inputData.length
         );
      },

      del(index) {
         const arr = this.inputData;
         this.inputData.splice(index, 1);
         let flag;
         if (arr.length) {
            flag = !!this.isEmpty(arr[arr.length - 1]);
         } else {
            flag = false;
         }
         this.$emit("getSection", this.inputData, flag);
         console.log(flag);
      },

      emitValue(e, k, value) {
         if (value == "goodsPsSphStart") {
            if (e < this.sectionmax) {
               this.isgoodsPsSphEnd = false;
            }
            if (this.inputData[k][value] == this.sectionmax) {
               this.inputData[k]["goodsPsSphEnd"] = "";
               this.isgoodsPsSphEnd = true;
            }
         } else if (value == "goodsPsCylStart") {
            if (e < this.sectionmaxZJ) {
               this.isgoodsPsCylEnd = false;
            }
            if (this.inputData[k][value] == this.sectionmaxZJ) {
               this.inputData[k]["goodsPsCylEnd"] = "";
               this.isgoodsPsCylEnd = true;
            }
         }
         const arr = this.inputData;
         this.$emit(
            "getSection",
            this.inputData,
            !!this.isEmpty(arr[arr.length - 1]) && !!this.inputData.length
         );
      },

      isEmpty(target) {
         return (
            target.goodsPsCylStart &&
            target.goodsPsCylEnd &&
            target.goodsPsCylSpan &&
            // target.goodsPsDiameterEnd &&
            // target.goodsPsDiameterSpan &&
            // target.goodsPsDiameterStart &&
            target.goodsPsSphStart &&
            target.goodsPsSphSpan &&
            target.goodsPsSphEnd
         );
      },

      section(start, end) {
         let arr = [start];
         for (let i = 0; i < 99999; i++) {
            if (arr[arr.length - 1] === end) {
               return arr;
            }
            let num = arr[arr.length - 1] + 0.25;
            arr.push(num);
         }
      },

      getSpan(start, end) {
         const abs = Math.abs(Math.abs(start) - Math.abs(end));
         const spanList = this.spanList.map((item) => item * 1);
         return spanList.filter((item) => item <= abs);
      },

      ZJSection(from) {
         return this.numberList.filter((item) => item > from);
      },

      ZJSpan(start, end) {
         const abs = Math.abs(Math.abs(start) - Math.abs(end));
         const spanList = this.diameterSpanList.map((item) => item * 1);
         return spanList.filter((item) => item <= abs);
      },
   },
};
</script>

<style scoped>
.box_up {
   padding: 3px 0;
   padding-left: 10px;
   border: 1px solid #e4e7ed;
}

.box_down {
   padding: 5px;
   border: 1px solid #e4e7ed;
   border-top: none;
}

.empty {
   width: 400px;
   padding: 10px 0;
   border: 1px solid #e4e7ed;
   display: flex;
   justify-content: center;
   align-items: center;
}

.b {
   border-top: none;
   /* border-bottom: none; */
}

.flex_box_main {
   display: flex;
   flex-wrap: wrap;
}

.flex_box {
   width: 400px;
   margin-right: 10px;
   margin-bottom: 10px;
}

.flex_box:hover .del {
   /* display: block; */
}

.del {
   /* display: none; */
}

.fl_right {
   padding-right: 10px;
   display: flex;
   justify-content: flex-end;
}
</style>
