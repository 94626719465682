<template>
  <div>
    <div class="flex_b">
    <el-button size="mini" @click="add">新增SKU</el-button>
    <el-button size='mini' type="primary" @click="updateSKU" v-if="inputData.length">{{ (inputData[inputData.length -1] || this.inputData.length > 1)?'更新 SKU':'取消添加' }}</el-button>
    </div>
    <div v-if="inputData.length" class='f_c'>
      <el-alert
          title="同一SKU不同项之间拼接使用“/”"
          type="warning">
      </el-alert>
      <div class="flex_box mt10" v-for="(item, index) in inputData" :key="index">
        <el-input
          size="mini"
          placeholder="请输入SKU"
          v-model="inputData[index]"
          @input="emitValue"
          @keyup.enter.native="add"
          :ref="index + 1"
        >
          <template slot="prepend">SKU:</template>
        </el-input>
        <el-button size="mini" type="text ml20" @click="del(index)">删除</el-button>
      </div>
    </div>
    <!-- <div class="flex_box_r">
      <el-button type="primary" @click="updateSKU" v-if="inputData.length">{{ (inputData[inputData.length -1] || this.inputData.length > 1)?'更新 SKU':'取消' }}</el-button>
    </div> -->
    <!-- <div class="empty mt5" v-else>
      暂无SKU
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
  },

  data() {
    return {
      inputData: [],
    }
  },

  watch: {
    value: {
      handler(val) {
        this.inputData = val || []
      },
      immediate: true,
    },

    inputData: {
      handler() {
        const l = this.inputData.length
        this.$nextTick(() => {
          if(l == 0) return
          this.$refs[l][0]?.$el?.childNodes[1].focus()
        })
      },
    },
  },

  methods: {
    updateSKU() {
      if(!this.inputData[this.inputData.length- 1] && this.inputData.length == 1) {
        // this.$message.error('SKU为空，无法新增')
        this.$emit('isEmpty')
        return
      }
      this.$emit("updateSKU")
    },

    reset() {
      this.inputData = []
    },

    add() {
      const arr = this.inputData
      const message = "请输入完整的SKU再继续创建"
      if (arr.length && !arr[arr.length - 1]) {
        this.$message({
          center: true,
          type: "error",
          message,
        })
        return
      }
      this.inputData.push("")
    },

    del(index) {
      this.inputData.splice(index, 1)
      this.$emit("input", this.inputData, !!this.inputData.length)
    },

    emitValue() {
      this.$emit("input", this.inputData, !!this.inputData.length)
    },
  },
}
</script>
<style scoped>
.flex_box {
  padding-right: 10px;
  display: flex;
}

.empty {
  padding: 5px 0;
  border: 1px solid #e4e7ed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_box_r {
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.flex_b {
  display: flex;
  justify-content: space-between;
}

.f_c {
  padding: 10px;
  /* padding-bottom: 0; */
  border: 2px solid #f5f7fa;
  margin-top: 10px;
  overflow: auto;
  height: calc(100vh - 490px);
}
</style>
