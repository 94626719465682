<template>
   <div class="contactLens">
      <div v-show="!isPage">
         <!-- 搜索 -->
         <div ref="search" class="search">
            <!-- 头部搜索栏 -->
            <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
               <el-form :inline="true" :model="searchForm" ref="searchForm" :rules="searchRules" label-width="80px">
                  <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
                  <el-form-item label="制造商">
                     <el-select v-model="searchForm.goodsGiCustomerId" :size="$store.state.size" placeholder="制造商" clearable @clear="handleGoodsGiCustomerIdClear">
                        <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="getBrandList(e.sysCiId, true)"></el-option>
                     </el-select>
                  </el-form-item>

                  <el-form-item label="品牌">
                     <el-select filterable v-model="searchForm.goodsGiBrandId" :size="$store.state.size" placeholder="品牌" clearable :disabled='!searchForm.goodsGiCustomerId' @clear="handleGoodsGiBrandIdClear" @change="goodsGiBrandChage">
                        <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId" @click.native="getProductListBySearch(e.goodsBiId, true)"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="所属品种">
                     <el-select filterable v-model="searchForm.goodsGiProductId" :size="$store.state.size" placeholder="所属品种" clearable :disabled='!searchForm.goodsGiBrandId'>
                        <el-option v-for="e in ProductListBySearch" :key="e.goodsPiId" :label="e.goodsPiName" :value="e.goodsPiId"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="商品名称">
                     <el-input v-model="searchForm.goodsGiName" :size="$store.state.size" placeholder="商品名称" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
                  </el-form-item>
                  <el-form-item label="标准SKU" v-show="searchShow">
                     <el-input v-model="searchForm.goodsGiSku" :size="$store.state.size" placeholder="SKU" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
                  </el-form-item>
                  <el-form-item label="球镜" prop="goodsIciSph" v-show="searchShow">
                     <el-input v-model="searchForm.goodsGiSph" :size="$store.state.size" placeholder="球镜" clearable @blur="$common.blur($event)" @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
                  </el-form-item>
                  <el-form-item label="柱镜" v-show="searchShow" prop="goodsIciCyl">
                     <el-input v-model="searchForm.goodsGiCyl" :size="$store.state.size" placeholder="柱镜" clearable @blur="$common.blur($event)" @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="轴向" prop="goodsIciAxial" v-show="searchShow" rop="goodsIciAxial">
              <el-input v-model="searchForm.goodsGiAxial" :size="$store.state.size" placeholder="轴向" clearable
                        @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
            </el-form-item>

             <el-form-item label="直径" prop="goodsIciAxial" v-show="searchShow" rop="goodsIciAxial">
              <el-input v-model="searchForm.goodsGiDia" :size="$store.state.size" placeholder="直径" clearable
                        @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
            </el-form-item> -->

                  <el-form-item label="商品类型" v-show="searchShow">
                     <el-select v-model="searchForm.goodsCategory" :size="$store.state.size" placeholder="商品类型" clearable @change="getProductListBySearch()">
                        <el-option v-for="e in productTypeList" :key="e.goodsPcId" :label="e.goodsPcName" :value="e.goodsPcId"></el-option>
                     </el-select>
                  </el-form-item>

                  <el-form-item label="启用状态" v-show="searchShow">
                     <el-select v-model="searchForm.goodsGiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                        <el-option label="启用" :value="0"></el-option>
                        <el-option label="停用" :value="1"></el-option>
                     </el-select>
                  </el-form-item>
               </el-form>
               <el-divider></el-divider>
               <el-button v-if="hasPermission('3-2-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true,'searchForm')" icon="el-icon-search">查询
               </el-button>
               <el-button v-if="hasPermission('3-2-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
               </el-button>
               <el-button v-if="hasPermission('3-2-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
               </el-button>
               <el-button v-if="hasPermission('3-2-h')" type="primary" :size="$store.state.size" @click="bathAddSku" icon="el-icon-circle-plus-outline">批量添加SKU
               </el-button>
            </el-card>
            <!-- 头部搜索栏 -->
         </div>

         <!-- 表格 -->
         <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
            <el-table-column prop="sysCiFullName" align="center" label="制造商名称">
            </el-table-column>
            <el-table-column prop="goodsBiName" align="center" label="品牌名称">
            </el-table-column>
            <el-table-column prop="goodsGiName" align="center" min-width="120px" label="商品名称">
            </el-table-column>
            <el-table-column prop="goodsGiSku" align="center" min-width="120px" label="标准SKU">
            </el-table-column>
            <el-table-column prop="goodsGiSph" align="center" label="球镜">
            </el-table-column>
            <el-table-column prop="goodsGiCyl" align="center" label="柱镜">
            </el-table-column>
            <!-- <el-table-column
            prop="goodsGiDia"
            align="center"
            width="100"
            label="直径">
        </el-table-column>
        <el-table-column
            prop="goodsGiAxial"
            align="center"
            width="100"
            label="轴向">
        </el-table-column> -->
            <!-- <el-table-column
            prop="goodsIciAxial"
            align="center"
            width="100"
            label="描述">
        </el-table-column> -->
            <el-table-column align="center" label="启用状态" width="150">
               <template slot-scope="scope" width="150">
                  <!-- {{ scope.row }} -->
                  <el-switch v-model="scope.row.goodsGiStatus" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用" @change="stopBtn(scope.row)">
                  </el-switch>
               </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="280">
               <template slot-scope="scope">
                  <el-button v-if="hasPermission('3-2-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看
                  </el-button>
                  <el-button v-if="hasPermission('3-2-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
                  </el-button>
                  <!-- <el-button v-if="hasPermission('3-2-e')" type="text" size="small" @click="seeAddSkuDialog(scope.row)" icon="el-icon-edit-outline">添加非标SKU</el-button></el-button> -->
                  <el-button v-if="hasPermission('3-2-g')" type="text" size="small" @click="seeOtherSKU(scope.row)" icon="el-icon-edit-outline">查看非标SKU
                  </el-button>
                  <el-button v-if="hasPermission('3-2-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除
                  </el-button>
               </template>
            </el-table-column>
         </el-table>

         <!-- 分页组件 -->
         <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
         </pagination>
      </div>

      <!-- 查看-->
      <assembly-dialog id="dialogSee" title="查看" :visible.sync="dialogVisibleSee" width="30%" @before-close="dialogVisibleSee=false">
         <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
            {{ seeForm }}
            <el-descriptions-item>
               <template slot="label">
                  制造商
               </template>
               {{ seeForm.sysCiFullName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  品牌
               </template>
               {{ seeForm.goodsBiName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  所属品种
               </template>
               {{ seeForm.goodsPiName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  SKU
               </template>
               {{ seeForm.goodsGiSku || '未设置' }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  球镜
               </template>
               {{ seeForm.goodsGiSph }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  柱镜
               </template>
               {{ seeForm.goodsGiCyl }}
            </el-descriptions-item>
            <!--        <el-descriptions-item>-->
            <!--          <template slot="label">-->
            <!--            轴向-->
            <!--          </template>-->
            <!--          {{ seeForm.goodsGiAxial || '未设置' }}-->
            <!--        </el-descriptions-item>-->
            <el-descriptions-item>
               <template slot="label">
                  启用状态
               </template>
               {{ seeForm.goodsGiStatus == 0 ? '启用' : '停用' }}
            </el-descriptions-item>
         </el-descriptions>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="dialogVisibleSee = false">关 闭</el-button>
         </span>
      </assembly-dialog>

      <!-- 查看非标 sku -->
      <assembly-dialog id="dialogSeeSKU" :visible='true' title="查看非标准 SKU" v-if="seeSKUDialog" @close='closeOtherDialog' @open='getOtherSKU' width="50%" @before-close="seeSKUDialog=false">
         <div class="dialog_box">
            <sku-select ref='skuSelect' v-model='skuList' @updateSKU='seeClose' @isEmpty='resetSkUSelect'></sku-select>
            <div class="mt20 mb10" v-show='otherSKUList.length'></div>
            <sku-list-page v-if="otherSKUList.length && !skuList.length" :otherSKUList='otherSKUList'></sku-list-page>
            <div class="is_empty" v-if="!otherSKUList.length && !skuList.length"><i class="el-icon-warning-outline mr10"></i> 非标SKU是空的
            </div>
         </div>
         <div class="end">
            <el-button class="mt20" @click="seeSKUDialog=false">关闭</el-button>
         </div>
      </assembly-dialog>

      <!--  批量添加 sku 弹框-->
      <assembly-dialog id="dialogAddSKU" title="批量添加SKU" :visible.sync="addSKUDialog" width="30%" @close='closeAddSKUDialog' @before-close="addSKUDialog=false;isAdd=false">
         <el-form :model="SKUform" :rules="SKURules" ref="addSkuForm" label-width="80px">
            <el-form-item label="制造商" prop="goodsGiCustomerId">
               <el-select v-model="SKUform.goodsGiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="getBrandList(e.sysCiId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="goodsGiBrandId">
               <el-select v-model="SKUform.goodsGiBrandId" :size="$store.state.size" placeholder="品牌" clearable :disabled='!SKUform.goodsGiCustomerId'>
                  <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId" @click.native="getProductList(SKUform.goodsGiBrandId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="所属品种" prop="goodsGiProductId">
               <el-select filterable v-model="SKUform.goodsGiProductId" :size="$store.state.size" placeholder="品种" clearable :disabled='!SKUform.goodsGiBrandId' @change="selectP">
                  <el-option v-for="e in ProductList" :key="e.goodsPiId" :label="e.goodsPiName" :value="e.goodsPiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="SKU前缀" prop="sku">
               <el-input v-model="SKUform.sku" placeholder="SKU"></el-input>
            </el-form-item>
            <!-- <el-form-item label="分隔符" prop="splitCode">
            <el-input v-model.trim="SKUform.splitCode" placeholder="分隔符"></el-input>
        </el-form-item> -->
         </el-form>
         <template v-slot:footer>
            <el-button type="primary" @click="addSKUFast">批量添加</el-button>
         </template>
      </assembly-dialog>

      <!-- 编辑框 -->
      <assembly-dialog id="dialogSave" title="编辑" :visible.sync="dialogVisibleSave" v-if='dialogVisibleSave' width="40%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item label="制造商" prop="goodsGiCustomerId" v-if="!isEditStatus">
               <el-select v-model="saveForm.goodsGiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="getBrandList(e.sysCiId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="goodsGiBrandId" v-if="!isEditStatus">
               <el-select v-model="saveForm.goodsGiBrandId" :size="$store.state.size" placeholder="品牌" clearable :disabled='!saveForm.goodsGiCustomerId'>
                  <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId" @click.native="getProductList(saveForm.goodsGiBrandId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="商品类型" prop="goodsCategory" v-if="!isEditStatus">
               <el-select v-model="saveForm.goodsCategory" :size="$store.state.size" placeholder="商品类别" clearable @change="productType" :disabled='!saveForm.goodsGiBrandId'>
                  <!-- 商品类型禁止了除了镜片类型（type =3）的其他类型 ？？？？因为其他类型的逻辑还未确定 、、 TODO  杨帆-->
                  <el-option v-for="e in productTypeList" :key="e.goodsPcId" :label="e.goodsPcName" :value="e.goodsPcId" :disabled='e.goodsPcId != 3'></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="所属品种" prop="goodsGiProductId" v-if="!isEditStatus">
               <el-select v-model="saveForm.goodsGiProductId" :size="$store.state.size" placeholder="品种" clearable :disabled='!saveForm.goodsCategory'>
                  <el-option v-for="e in ProductList" :key="e.goodsPiId" :label="e.goodsPiName" :value="e.goodsPiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="goodsGiName">
               <el-input :size="$store.state.size" v-model="saveForm.goodsGiName"></el-input>
            </el-form-item>
            <!-- 单品类，仅眼镜类别  临时禁用全种类 -->
            <!-- <el-form-item label="商品类型" prop="goodsCategory" v-if="!isEditStatus">
          <el-select v-model="saveForm.goodsCategory" :size="$store.state.size" placeholder="商品类别" clearable @change="productType">
            <el-option label="镜片类" :value="'3'"></el-option>
          </el-select>
        </el-form-item> -->

            <!-- 全种类 商品类别 -->
            <!-- <el-form-item label="商品类型" prop="goodsCategory">
          <el-select v-model="saveForm.goodsCategory" :size="$store.state.size" placeholder="商品类别" clearable @change="productType">
            <el-option v-for="e in productTypeList" :key="e.goodsPcId" :label="e.goodsPcName" :value="e.goodsPcId"></el-option>
          </el-select>
        </el-form-item> -->

            <el-form-item label="SKU" prop='goodsGiSku' :rules="{ required: true, message:'请输入SKU',trigger: 'blur' }">
               <el-input v-model="saveForm.goodsGiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="分隔符" prop="splitCode" v-if="!isEditStatus">
          <el-input v-model="saveForm.splitCode" :size="$store.state.size" placeholder="分隔符" clearable></el-input>
        </el-form-item> -->
            <el-form-item label='区间' v-if="!isEditStatus && saveForm.goodsCategory == 3">
               <section-select ref="sectionRef" @getSection='setSection'></section-select>
            </el-form-item>
            <el-form-item label="启用状态" prop="goodsGiStatus" v-if="!isEditStatus">
               <el-select v-model="saveForm.goodsGiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="停用" :value="1"></el-option>
               </el-select>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>

      <!-- 新增商品 -->
      <transition name='el-fade-in'>
         <assembly-card-page title="新增商品" v-if="isPage" @back='isPage=false;isAdd = false;'>
            <el-form :model="saveForm" :rules="saveRules" ref="saveFormPage" label-width="100px">
               <div class="flex_box_form">
                  <el-form-item label="制造商" prop="goodsGiCustomerId" v-if="!isEditStatus">
                     <el-select v-model="saveForm.goodsGiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                        <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="getBrandList(e.sysCiId)"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="品牌" prop="goodsGiBrandId" v-if="!isEditStatus">
                     <el-select filterable v-model="saveForm.goodsGiBrandId" :size="$store.state.size" placeholder="品牌" clearable :disabled='!saveForm.goodsGiCustomerId'>
                        <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId" @click.native="getProductList(saveForm.goodsGiBrandId)"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="商品类型" prop="goodsCategory" v-if="!isEditStatus">
                     <el-select v-model="saveForm.goodsCategory" :size="$store.state.size" placeholder="商品类别" clearable @change="productType" :disabled='!saveForm.goodsGiBrandId'>
                        <el-option v-for="e in productTypeList" :key="e.goodsPcId" :label="e.goodsPcName" :value="e.goodsPcId"></el-option>
                     </el-select>
                  </el-form-item>
               </div>
               <div class="flex_box_form">
                  <el-form-item label="所属品种" prop="goodsGiProductId" v-if="!isEditStatus">
                     <el-select filterable v-model="saveForm.goodsGiProductId" :size="$store.state.size" placeholder="品种" clearable :disabled='!saveForm.goodsCategory' @change="selectGoods">
                        <el-option v-for="e in ProductList" :key="e.goodsPiId" :label="e.goodsPiName" :value="e.goodsPiId"></el-option>
                     </el-select>
                  </el-form-item>

                  <el-form-item label="商品名称" prop="goodsGiName">
                     <el-input style="width:205px" :size="$store.state.size" v-model="saveForm.goodsGiName"></el-input>
                  </el-form-item>

                  <el-form-item label="SKU前缀" prop='goodsGiSku'>
                     <el-input style="width:205px" v-model="saveForm.goodsGiSku" :size="$store.state.size" placeholder="SKU" clearable></el-input>
                  </el-form-item>
               </div>
               <div class="flex_box_form" v-if="saveForm.goodsCategory && saveForm.goodsCategory != 3 && saveForm.goodsCategory != 4">
                  <el-form-item label="型号" prop="goodsGiModel">
                     <el-input style="width:205px" v-model="saveForm.goodsGiModel" :size="$store.state.size" placeholder="型号" clearable></el-input>
                  </el-form-item>

                  <el-form-item label="色号" prop="goodsGiColour">
                     <el-input style="width:205px" v-model="saveForm.goodsGiColour" :size="$store.state.size" placeholder="色号" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="度数" prop="goodsGiDegree" v-if="saveForm.goodsCategory == 8">
                     <el-input style="width:205px" v-model="saveForm.goodsGiDegree" :size="$store.state.size" placeholder="度数" clearable></el-input>
                  </el-form-item>
               </div>
               <el-form-item label='区间' v-if="!isEditStatus && saveForm.goodsCategory == 3 || saveForm.goodsCategory == 4" prop="sectionList">
                  <section-select ref="sectionRef" @getSection='setSection'></section-select>
               </el-form-item>
               <el-form-item label="启用状态" prop="goodsGiStatus" v-if="!isEditStatus">
                  <el-radio v-model="saveForm.goodsGiStatus" :label="0"> {{ '启用' }}</el-radio>
                  <el-radio v-model="saveForm.goodsGiStatus" :label="1"> {{ '停用' }}</el-radio>
               </el-form-item>

            </el-form>
            <el-button :size="$store.state.size" @click="clearForm('saveFormPage')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveFormPage')">确 定</el-button>
         </assembly-card-page>
      </transition>
   </div>

</template>

<script>
// 组件导入
//#region
import skuListPage from '@/page/commodity/contactLens/components/skuListPage'
import sectionSelect from './components/sectionSelect.vue'
import skuSelect from './components/skuSelect.vue'
import searchOpenBtn from "@/components/assembly-search/search-open-btn";
import pagination from "@/components/assembly-pagination/index";
import regular from "../../../assets/js/regular";
import assemblyCardPage from '@/components/assembly-card-page'
import { mixin } from './mixin'
import SkuListPage from './components/skuListPage.vue';
import AssemblyDialog from "components/assembly-dialog";
//#endregion

export default {
   name: "contactLens",
   mixins: [mixin],

   components: {
      AssemblyDialog,
      skuListPage,
      searchOpenBtn,
      pagination,
      skuSelect,
      sectionSelect,
      assemblyCardPage,
      SkuListPage
   },

   watch: {
      seeSKUDialog(val) {
         if (val) {
            this.getOtherSKU()
         }
      }
   },
   data() {
      return {
         currentPageSize: 10,
         isPage: false, // 是否再新增页面 - 弹框改成单页面
         SKUform: {}, //
         addSKUDialog: false, // 查看 suk 显示吗
         otherSKUList: [],
         seeSKUDialog: false,
         currentEditRow: {}, // 当前编辑行
         skuList: [], // 存放 sku 数组
         productTypeList: [], // 商品类别存放
         isEditStatus: false, // 当前是否是编辑状态，用来区分三个区间信息
         //保存模态框开关
         dialogVisibleSave: false,
         //保存表单
         saveForm: {
            goodsIciCustomerId: '',
            goodsIciProductId: '',
            goodsIciProductType: '',
            goodsIciSku: '',
            goodsIciSph: '',
            goodsIciCyl: '',
            goodsIciAxial: '',
            goodsIciStatus: '',
            goodsIciBrandId: '',
            skuFlag: '', // sku 标志
            sectionFlag: '', // 区间标志
            goodsGiStatus: 0
         },
         //制造商列表
         manufacturerList: [],
         //  商品名称列表
         ProductList: [],
         //商品名称列表
         ProductListBySearch: [],
         //品牌列表
         brandList: [],
         //搜索品名列表
         searchProductList: [],
         //品名搜索集合
         goodsPiNameList: [],
         isSave: 1, //编辑查询传1，查询传0
         isAdd: false
         //  this.isAdd = false;
         //  this.isSave = this.isAdd == true ? 0 : 1
      }
   },
   methods: {
      selectP(val) {
         const list = this.ProductList
         const id = val
         this.SKUform.goodsPiCategoryId = list.filter(item => item.goodsPiId == id)[0]?.goodsPiCategoryId
      },

      setSection(val, flag) {
         if (flag) {
            // this.saveForm.sectionList = this.toS(val)
            this.$set(this.saveForm, 'sectionList', this.toS(val))
         } else {
            this.$set(this.saveForm, 'sectionList', undefined)
            // this.saveForm.sectionList = undefined
         }
      },
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      //制造商列表
      manufacturer() {
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.getList({ sysCiType: 1, userid: user.sysUiId, isSave: this.isSave }).then((res) => {
            if (res.ok) {
               this.manufacturerList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //获取品牌列表
      getBrandList(row, isSearch) {
         if (isSearch) {
            this.$set(this.searchForm, 'goodsGiBrandId', undefined)
            this.$set(this.searchForm, 'goodsGiProductId', undefined)
         } else {
            this.$set(this.saveForm, 'goodsGiBrandId', undefined)
            this.$set(this.saveForm, 'goodsGiProductId', undefined)
         }
         this.$set(this.brandList, [])
         this.isSave = this.isAdd == true ? 0 : 1
         this.$forceUpdate()
         this.$server.getBrandList({ 'goodsBiCustomerId': row, isSave: this.isSave }).then(res => {
            if (res.ok) {
               this.brandList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //获取品名列表(搜索框)
      getProductListBySearch(row) {
         this.$set(this.saveForm, 'goodsGiProductId', undefined)
         this.ProductList = []
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.getGoodsPiNameList({
            goodsPiBrandId: row ? row : this.searchForm.goodsGiBrandId,
            goodsPiCustomerId: this.searchForm.goodsGiCustomerId,
            goodsPiName: this.searchForm.goodsPiName,
            isLens: 1,
            goodsPiCategoryId: this.searchForm.goodsCategory,
            isSave: this.isSave
         }).then((res) => {
            if (res.ok) {
               this.ProductListBySearch = res.data;
               // this.saveForm.goodsGiSku = res.data
            }
            this.$forceUpdate()
         }).catch(e => {
            console.log(e)
         })
      },
      //获取品名列表
      getProductList(row) {
         this.$set(this.saveForm, 'goodsGiProductId', undefined)
         this.ProductList = []
         this.isSave = this.isAdd == true ? 0 : 1
         // goodsPiCategoryId: this.saveForm.goodsCategory 保留参数，为什么？新增时带上这个参数就请求不到了
         this.$server.getGoodsPiNameList({
            goodsPiBrandId: row,
            goodsPiCustomerId: !this.addSKUDialog ? this.saveForm.goodsGiCustomerId : this.SKUform.goodsGiCustomerId,
            goodsPiName: this.searchForm.goodsPiName,
            isLens: 1,
            goodsPiCategoryId: this.saveForm.goodsCategory,
            isSave: this.isSave
         }).then((res) => {
            if (res.ok) {
               this.ProductList = res.data;
               // this.saveForm.goodsGiSku = res.data
            }
            this.$forceUpdate()
         }).catch(e => {
            console.log(e)
         })
      },
      /****搜素区域 start********************************************************************************/

      //搜索表单提交
      searchSubmit(flag, searchForm) {
         var goodsGiSph = {
            target: {
               value: this.searchForm.goodsGiSph
            }
         }
         var goodsGiCyl = {
            target: {
               value: this.searchForm.goodsGiCyl
            }
         }
         this.searchForm.goodsGiSph = this.$common.blur(goodsGiSph)
         this.searchForm.goodsGiCyl = this.$common.blur(goodsGiCyl)
         if (flag) {
            this.currentPage = 1
            this.searchForm.page = 1
            this.searchForm.pageSize = this.currentPageSize
         }
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.searchForm.userid = user.sysUiId;
         this.$refs[searchForm].validate((valid) => {
            if (valid) {
               this.tableLoading = true
               console.log('搜索表单提交', JSON.stringify(this.searchForm))
               this.$server.getProductTableData(this.searchForm).then((res) => {
                  this.tableLoading = false
                  if (res.ok) {
                     const tableData = res.data.records
                     tableData.map(item => item.goodsGiStatus = item.goodsGiStatus * 1)
                     this.tableData = tableData
                     this.total = res.data.total
                  }
               }).catch(e => {
                  this.tableLoading = false
                  console.log(e)
               })
            } else {
               this.tableLoading = false
               return false;
            }
         });
      },
      //搜索表单重置
      resetSubmit() {
         this.currentPage = 1
         // console.log(this.$refs.searchForm,'yyyyyyyyy')
         this.$common.clearFormValue(this.searchForm)
         // this.$refs.assemblyAutocomplete.clear()
         this.$refs.searchForm.resetFields()
         // this.searchSubmit(false,'searchForm');
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.currentPageSize
         this.searchSubmit(false, 'searchForm');
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //保存表单提交
      saveOpen() {
         const data = {
            goodsIciCustomerId: '',
            goodsIciProductId: '',
            goodsIciProductType: '',
            goodsIciSku: '',
            goodsIciSph: '',
            goodsIciCyl: '',
            goodsIciAxial: '',
            goodsIciStatus: '',
            goodsIciBrandId: '',
            skuFlag: '', // sku 标志
            sectionFlag: '', // 区间标志
            goodsGiStatus: 0
         }
         this.saveForm = data
         // this.$refs.sectionRef.reset()
         this.isEditStatus = false
         this.isPage = true
         // this.dialogVisibleSave = true
         this.$nextTick(() => {
            try {
               this.$refs.sectionRef.reset()
            } catch {
            }
         })
         this.isAdd = true;
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.getCategoryList({ isSave: this.isSave }).then(res => {
            this.productTypeList = res.data
         })
      },
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.postProductData(this.saveForm).then(res => {
                  if (res.ok) {
                     this._resetPage()
                     this.$message.success(res.msg)
                     this.searchSubmit(false, 'searchForm');
                     this.dialogVisibleSave = false
                  } else {
                     this.$message.error(res.msg)
                     this.dialogVisibleSave = false
                     this.isAdd = false
                  }
                  this.isPage = false
                  this.clearForm('saveForm')
                  this.$common.clearFormValue(this.saveForm)
                  this.saveForm.goodsGiStatus = 0
                  this.searchForm.goodsGiCustomerId = ''
               }).catch(e => {
                  console.log(e)
                  this.searchForm.goodsGiCustomerId = ''
                  this.dialogVisibleSave = false
                  this.isAdd = false
               })
            } else {
               return false;
            }
         });
      },
      //清空表单
      clearForm(formName) {
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;
         this.$refs[formName].resetFields();
         if (!this.isPage) {
            this.saveForm.goodsGiName = "";
            this.saveForm.goodsGiSku = "";
            if (!this.isEditStatus) {
               this.saveForm.goodsGiCustomerId = "";
               this.saveForm.goodsGiBrandId = "";
               this.saveForm.goodsCategory = "";
               this.saveForm.goodsGiProductId = "";
               this.saveForm.goodsGiStatus = "";
            }
         } else {
            this.$common.clearFormValue(this.saveForm)
         }
      },
      //编辑模态框开启
      editOpen(row) {
         this.isEditStatus = true
         this.saveForm = JSON.parse(JSON.stringify(row))
         this.dialogVisibleSave = true
         this.isAdd = false;
      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         // this.seeSKUDialog = true
         console.log(row)
         this.seeForm = row
         console.log('查看区域==', JSON.stringify(row))

         this.dialogVisibleSee = true
      },

      resetSkUSelect() {
         this.skuList = []
      },

      seeClose() {
         // this.getOtherSKU()
         // TODO 提交数据
         if (!this.skuList.length) {
            return
         }
         this.$server.addSkuList({ ...this.currentEditRow, skuList: this.skuList }).then(res => {
            if (res.ok) {
               this.$message({
                  type: 'success',
                  message: res.msg
               });
               this.skuList = []
               this.getOtherSKU()
            } else {
               this.$message.error(res.msg)
               this.skuList = []
            }
            // this.closeAddSkuDialog()
         })
      },

      //商品类型修改
      productType(row) {
         if (row == 1) {
            this.saveForm.goodsIciCyl = ''
            this.saveForm.goodsIciSph = ''
         }
         this.getProductList(this.saveForm.goodsGiBrandId)
      },


      handleGoodsGiCustomerIdClear() {
         this.searchForm.goodsGiBrandId = "";
         this.brandList = []
         this.handleGoodsGiBrandIdClear();
      },
      handleGoodsGiBrandIdClear() {
         this.searchForm.goodsGiProductId = "";
         this.ProductList = []
      },

      goodsGiBrandChage() {
         this.searchForm.goodsGiProductId = "";
      },
      bathAddSku() {
         this.addSKUDialog = true;
         this.isAdd = true

      }
   },
}
</script>

<style lang="scss">
.dialog_box {
   height: calc(100vh - 400px);
   overflow: auto;
}

.contactLens {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}

.flex_box_form {
   display: flex;
   padding-right: 10px;
}

.end {
   padding-right: 8px;
   display: flex;
   justify-content: flex-end;
}

.is_empty {
   height: calc(100vh - 500px);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 20px;
}

.mr10 {
   margin-top: 5px;
   font-size: 30px;
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
